.mainDiv{
    height: 100vh;
    width: 100vw;
    background-color: #F4F4F4;
    
}

.route{
    color: #4F4F4F; 
    padding-top: 20px;
    padding-left: 30px;
    font-size: small;
}

.title{
    display: inline-block;
    font-weight: 600;
    color: #1C2D5D;
    padding-left: 30px;
    padding-top: 20px;
    padding-bottom: 15px;
    font-size: larger;
}

.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    max-width: 100vw;
    height: max-content;
    margin-left:30px;
    margin-right:30px;
    padding: 20px 30px ;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: sans-serif;
    border: 1px solid #BDBDBD;
}

.divDadosGerais{
    align-self: flex-start;
    width: 500px;
    height: max-content;
    padding-bottom: 30px;
    color: #3C5896;
    font-weight: 600;
    align-self: flex-start;
}

.linha{
    width: 100%;
    border-bottom: 1px solid #BDBDBD;  
}

.divContato{
    color: #3C5896;
    font-weight: 600;
    align-self: flex-start;
    margin-bottom: 30px;
}

.divDadosParceiro{
    font-weight: 600;
    color: #4F4F4F;
    font-size: medium;
    height: max-content;
}

.divContatoParceiro{
    font-weight: 600;
    color: #4F4F4F;
    font-size: medium;
}

.divDadosParceiros{
    color: #3C5896;
    font-weight: 600;
    align-self: flex-start;
    margin-bottom: 30px;
}

.divInfoParceiro{
    font-weight: 600;
    color: #4F4F4F;
    font-size: medium;
}

.p{
    font-weight: 500;
    
}

.divButton{ 
    display: inline-block;
    padding-top: 20px;
    padding-right:30px;       
    float: right;
    
}

.buttonEditar{
    background: #FFFFFF;
    border: 1px solid #208776;
    box-sizing: border-box;
    border-radius: 5px;
    color: #208776;
    font-weight: 600;
    width: 100px;
}

.buttonEditar:hover{
    background: #208776;
    border: 1px solid #208776;
    box-sizing: border-box;
    border-radius: 5px;
    color: #FFFFFF;
    font-weight: 600;
    width: 100px;
}

.buttonIcon{
    margin-right: 10px;
}
