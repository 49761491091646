.customModal {
    max-width: 600px;
    width: 90%;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
  }
  
  .modalBodyScrollable {
    overflow-y: auto;
    max-height: 400px;
    padding: 1rem;
  }
  
  .modalHeader {
    font-weight: 600;
    font-size: 1.2rem;
  }
  
  .modalFooter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Botões */
  .button {
    display: flex;
    justify-content: center;
    background: #208776;
    border-radius: 5px;
    font-weight: 600;
    width: 100px;
  }
  
  .button:hover {
    background: #196758;
  }
  
  /* Busca */
  .inputBusca {
    font-size: small;
  }
  
  .inputBusca::placeholder {
    font-size: small;
  }
  
  .textBuscar {
    background-color: #3C5896;
    font-size: small;
    color: #ffffff;
  }
  
  .iconBuscar {
    margin-right: 10px;
  }
  
  /* Tabela */
  .table {
    margin: auto;
    color: #4F4F4F;
    font-weight: 600;
  }
  
  .tbody {
    color: #4F4F4F !important;
  }
  
  /* Botão enviar */
  .buttonFormulario {
    font-weight: 600;
    color: #208776;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-size: small;
  }
  
  .buttonFormulario:hover {
    background-color: #B4DED8;
  }
  
  .buttonIcon {
    margin-right: 10px;
  }
  