.mainDiv{
    background-color: #FFFFFF;
    display: flex;
}

.iconDashboard, .iconVida, .iconAuxiliar, .iconParceiro, .iconEmpresa, .iconQuestionario{
    margin: 05px;
}

.navDashboard{
    display: flex;
    justify-content: center;
    margin-left: 15px;  
    color: #828282; 
    font-weight: 600;
}

.navVida{
    color: #828282; 
    font-weight: 600;
}

.navAuxiliar{
    color: #828282; 
    font-weight: 600;
}

.navParceiro{
    color: #828282; 
    font-weight: 600;
}

.navEmpresa{
    color: #828282;
    font-weight: 600;
}

.navListaQuestionario{
    color: #828282; 
    font-weight: 600;
}

.navDashboard:hover, .navVida:hover, .navAuxiliar:hover, .navParceiro:hover, .navEmpresa:hover, .navListaQuestionario:hover{
    color: #1C2D5D
}

.navDashboard:focus, .navVida:focus, .navAuxiliar:focus, .navParceiro:focus, .navEmpresa:focus, .navListaQuestionario:focus{
    color: #1C2D5D
}

.itensDropdown{
    color: #828282  
}

