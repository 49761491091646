.button{
    display: flex;
    justify-content: center;
    background: #208776;
    border-radius: 5px;
    font-weight: 600;
    width: 100px;
}

.button:hover{
    display: flex;
    justify-content: center;
    background: #196758;
    border-radius: 5px;
    font-weight: 600;
    width: 100px;
}

.inputBusca{
    font-size: small;
}

.inputBusca::placeholder{
    font-size: small;
}

.textBuscar{
    background-color: #3C5896;
    font-size: small;
    color: #ffffff;
}

.iconBuscar{
    margin-right: 10px;
}


.table{
    margin: auto;
    color: #4F4F4F;
    font-weight: 600;
    
}

.tbody{
    color: #4F4F4F !important;
}

.buttonFormulario{
    font-weight: 600;
    color:#208776; 
    border-radius: 5px;
    background-color: #FFFFFF;;
    font-size: small;
}

.buttonFormulario:hover{
    font-weight: 600;
    color:#208776; 
    border-radius: 5px;
    background-color: #B4DED8;;
    font-size: small;
}

.buttonIcon{
    margin-right: 10px;
}

.modalFooter{
    display: flex;
    justify-content: center;
    align-items: center;
}