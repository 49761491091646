.navbar{
    background-color: #3C5896;
    height: 40px;
}

.itensDropdown{
    color: #828282  
}

.divImage{
    display: flex;
    justify-content: center;
    margin-left: 20px;
}

.image{
    max-width:70px;
    max-height:70px;
    width: auto;
    height: auto;
}

.divDropdownToggle{
    
}   

.dropdownToggle{
    color: #F2F2F2;
}

.dropdownToggle:hover{
    color: #F2F2F2;
    transition: 0.5s;
    opacity: 0.7;
}

.dropdownToggle:any-link{
    color: #F2F2F2;
}

.divImagePerfil{
    display: flex;
    justify-content: center;
    margin-top: 05px;
}

.imagePerfil{
    border-radius: 50%;
    max-width:30px;
    max-height:30px;
    width: auto;
    height: auto;
}