.icon{
    display: flex;
    justify-content: center;
    color: #208776;
}

.divText{
    display: flex;
    justify-content: center;
    color: #3C5896;
    font-weight: 600;
    font-size: larger;
}

.button{
    display: flex;
    justify-content: center;
    background: #208776;
    border-radius: 5px;
    font-weight: 600;
    width: 100px;
}

.button:hover{
    display: flex;
    justify-content: center;
    background: #196758;
    border-radius: 5px;
    font-weight: 600;
    width: 100px;
}

.modalFooter{
    display: flex;
    justify-content: center;
    align-items: center;
}