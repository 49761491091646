.mainDiv{
    height: 100vh;
    width: 100vw;
    background-color: #778AB3; 
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.container{
    background-color: #ffffff;
    width: 400px;
    height: max-content;
    margin: auto;
    padding: 20px 30px;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: sans-serif;
    border: 1px solid #BDBDBD;
}

.divImage{
    display: flex;
    justify-content: center;
    margin-top: 10%;
}

.image{
    max-width:200px;
    max-height:150px;
    width: auto;
    height: auto
}

.labelUser{
    font-weight: 600;
    font-size: small;
    margin-top: 15%;
    margin-bottom:0%;
    color:#212529;;
}

.inputUser{
    background-color: #ffffff;
    min-height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: .875rem;
    border-radius: 5px;
    margin-bottom: 10%;
    border: 1px solid #BDBDBD;
}

.labelPassword{
    font-weight: 600;
    font-size: small;
    margin-bottom:0%;
    color:#212529;;
}

.inputPassword{
    background-color: #ffffff;
    min-height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: .875rem;
    border-radius: 5px;
    border: 1px solid #BDBDBD;
}

.buttonPassword{
    background-color: #ffffff;
    min-height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: .875rem;
    border-radius: 5px;
    border: 1px solid #BDBDBD;
}

.buttonPassword:hover{
    background-color: #124B3D;
}

.passwordIcon{
    color: #000000;
}

.checkBox{
    margin-top: 10%;
}

.checkBox:checked{
    background-color:#333333;
}

.rememberMe{
    color: #333333;
    padding-bottom: 20%;
    padding-top: 10%;
    font-weight: 600;
    font-size: small;
}

.button{
    width: 100%;
    background-color: #208776;
    border-color: #208776 ;
    padding: 0.25rem 0.5rem;
    font-size: small;
    border-radius: 0.2rem;
    font-weight: 600;
}

.button:hover{
    background-color: #124B3D;
}

.divForgotPassword{
    padding-top: 5%;
    text-align: right;
}

.forgotPassword{
    color: #3C5896;
    font-weight: 500;
    font-size: small;
    border: none;
}

.errorInput{
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #DC3545;
}

.errorDiv{
    font-weight: 400;
    color: #DC3545;
    font-size: smaller;
    margin-top: 5px;
}