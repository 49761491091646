/* Reset moderno */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Definição de variáveis globais */
:root {
  /* Paleta de cores */
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --background-color: #f8f9fa;
  --text-color: #212529;
  --card-bg: white;
  --button-hover: darkblue;

  /* Fontes */
  --font-body: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  --font-code: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Estilos globais do body */
body {
  font-family: var(--font-body);
  color: var(--text-color);
  background-color: var(--background-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Estilos para código */
code {
  font-family: var(--font-code);
}

/* Container responsivo */
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Estilo de botões */
.btn {
  display: inline-block;
  padding: 12px 24px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s, transform 0.2s;
}

.btn:hover {
  background-color: var(--button-hover);
  transform: scale(1.05);
}

/* Melhor acessibilidade do botão */
.btn:focus {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

/* Estilos para links */
a {
  text-decoration: none;
  color: var(--primary-color);
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}

a:hover {
  color: var(--button-hover);
}

/* Layout Flexbox para organização */
.row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.column {
  flex: 1;
  min-width: 250px;
  padding: 20px;
  background: var(--card-bg);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

/* Efeito hover nos cards */
.column:hover {
  transform: translateY(-5px);
}

/* Responsividade */
@media (max-width: 1024px) {
  .container {
    width: 95%;
  }
}

@media (max-width: 768px) {
  .row {
    flex-direction: column;
    align-items: center;
  }

  .column {
    width: 100%;
    max-width: 400px;
  }

  .btn {
    width: 100%;
    text-align: center;
  }
}
