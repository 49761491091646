.mainDiv {
    height: 100%;
    width: 100%;
    background-color: #F4F4F4;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    max-width: 100%;
    height: max-content;
    margin-left: 30px;
    margin-right: 30px;
    padding: 20px 30px;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: sans-serif;
    border: 1px solid #BDBDBD;
}

.title {
    display: inline-block;
    font-weight: 600;
    color: #1C2D5D;
    padding-left: 30px;
    padding-top: 20px;
    padding-bottom: 15px;
    font-size: larger;
}

.route {
    color: #4F4F4F;
    padding-top: 20px;
    padding-left: 30px;
    font-size: small;
}

.linha {
    width: 100%;
    border-bottom: 1px solid #BDBDBD;
}

.divDadosGerais {
    color: #3C5896;
    font-weight: 600;
    align-self: flex-start;
}

.label {
    color: #4F4F4F;
    font-weight: 600;
    font-size: medium;
    margin-top: 30px;
}


.divButton {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.buttonCacelar {
    font-weight: 600;
    color: #208776;
    background: #FFFFFF;
    border: 1px solid #208776;
    box-sizing: border-box;
    border-radius: 5px;
    margin-right: 30px;
    width: 120px;
}

.buttonCacelar:hover {
    font-weight: 600;
    color: #208776;
    background: #B4DED8;
    border: 1px solid #208776;
    box-sizing: border-box;
    border-radius: 5px;
    margin-right: 30px;
    width: 120px;
}

.buttonEditar {
    background: #208776;
    border-radius: 5px;
    font-weight: 600;
    color: #FFFFFF;
    width: 120px;
}

.buttonEditar:hover {
    background: #196758;
    border-radius: 5px;
    font-weight: 600;
    color: #FFFFFF;
    width: 120px;
}

.divContato {
    color: #3C5896;
    font-weight: 600;
    align-self: flex-start;
    margin-top: 30px;
}

.divDadosParceiros {
    color: #3C5896;
    font-weight: 600;
    align-self: flex-start;
    margin-top: 30px;
}

.errorInput {
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #DC3545;
}

.errorDiv {
    font-weight: 400;
    color: #DC3545;
    font-size: smaller;
    margin-top: 5px;
}

.dropdown {

    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-sizing: border-box;
}

.dropdownMenu {
    overflow-y: scroll;
    max-height: 200px;
}

.dropdownToggle {
    color: #212529;
    padding: 5px;
    font-weight: 400;
    font-size: small;
}

.dropdownToggle:hover {
    color: #1C2D5D;
    padding: 5px;
    font-weight: 400;
    font-size: small;
}

.dropdownToggle:focus {
    color: #1C2D5D;
    padding: 5px;
    font-weight: 400;
    font-size: small;
}

.dropdownItem {
    font-size: small;
}

.inputBusca {
    font-size: small;
}

.inputBusca::placeholder {
    font-size: small;
}
