.mainDiv {
    height: 100vh;
    width: 100vw;
    background-color: #F4F4F4;
  }
  
  .content {
    width: 95vw;
    margin: 30px auto;
    padding: 50px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  
  /* NOVO: linha que envolve título + botão */
  .headerRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* NOVO: estilo do título */
  .pageTitle {
    color: #1C2D5D;
    font-size: 32px;
    font-weight: 600;
    margin: 0;
  }
  
  /* Botão de voltar à direita */
  .backButton {
    background-color: #208776;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .backButton:hover {
    background-color: #196758;
  }
  