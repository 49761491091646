.mainDiv {
    min-height: 100vh;
    width: 100vw;
    background-color: #F4F4F4;
}

.route {
    color: #4F4F4F;
    padding-top: 20px;
    padding-left: 30px;
    font-size: small;
}

.title {
    font-weight: 600;
    color: #1C2D5D;
    padding-left: 30px;
    padding-top: 20px;
    padding-bottom: 15px;
    font-size: larger;
}

.container {
    width: 100%;
    padding: 0 30px 30px 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.leftColumn {
    flex: 6.9; /* Ajustado para 60% */
    background-color: #ffffff;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #BDBDBD;
}

.rightColumn {
    flex: 4; /* Ajustado para 40% */
    background-color: #ffffff;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #BDBDBD;
    max-height: 80vh;
    overflow-y: auto;
}

/* Adicionando espaçamento entre os filtros */
.filterRow {
    margin-bottom: 15px;
    display: flex;
}

.inputGroup {
    margin-bottom: 10px;
}

.chartColumn {
    margin-bottom: 20px;
}

.divInput {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}


.inputBusca {
    font-size: small;
    width: 100%;
}

.inputBusca::placeholder {
    font-size: small;
}

.textBuscar {
    background-color: #3C5896;
    font-size: small;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconBuscar {
    margin-right: 5px;
}

.buttonIcon {
    margin-right: 5px;
}

.buttonPerfil {
    font-weight: 600;
    border-radius: 5px;
    background-color: #208776;
    font-size: small;
    display: flex;
    align-items: center;
    gap: 5px;
}

.buttonPerfil:hover {
    background-color: #196758;
}

.table {
    width: 100%;
    margin: auto;
    color: #4F4F4F;
    font-weight: 600;
}

.tbody {
    color: #4F4F4F !important;
}

.buttonFormulario {
    font-weight: 600;
    color: #208776;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-size: small;
}

.buttonFormulario:hover {
    background-color: #B4DED8;
}

.selectFiltro {
    width: max-content;
    font-size: small;
}

.textFiltro {
    background-color: #3C5896;
    font-size: small;
    color: #ffffff;
    white-space: nowrap;
}

@media (max-width: 992px) {
    .container {
        flex-direction: column;
    }

    .leftColumn,
    .rightColumn {
        flex: none;
        width: 100%;
        padding: 15px;
    }

    .rightColumn {
        max-height: none;
    }
}

.contadorWrapper {
    display: flex;
    justify-content: flex-end;
}

.contadorBolha {
    width: 25px;
    height: 25px;
    background-color: #208776;
    color: white;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}