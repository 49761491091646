.mainDiv{
    min-height: 100vh;
    width: 100vw;
    background-color: #F4F4F4;
}

.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    max-width: 100vw;
    height: max-content;
    margin-left:30px;
    margin-right:30px;
    padding: 20px 30px ;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: sans-serif;
    border: 1px solid #BDBDBD;
}

.title{
    font-weight: 600;
    color: #1C2D5D;
    padding-left: 30px;
    padding-top: 20px;
    padding-bottom: 15px;
    font-size: larger;
}

.route{
    color: #4F4F4F; 
    padding-top: 20px;
    padding-left: 30px;
    font-size: small;
}

.listItem{
    color: #4F4F4F;
    font-weight: 600;
    
}