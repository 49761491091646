.mainDiv{
    min-height: 100vh;
    width: 100vw;
    background-color: #F4F4F4;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.divImage{
    
}

.image{
    max-width:400px;
    max-height:400px;
    width: auto;
    height: auto
}

.divSpinner{
    display: inline;
    margin-left: 20px;
}

.divText{
    display: inline;
    color: #1C2D5D;
    font-size: xx-large;
}

.spinner{
    color: #1C2D5D
}