.dashboardContainer {
  padding: 20px;
  background-color: #F4F4F4;
}

.chartContainer {
  margin-right: 20px;
  margin-bottom: 50px;
}

.chartsRow {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.chartItem {
  flex: 1;
  min-width: 300px;
}

.chartItem .echarts-for-react {
  width: 100% !important;
}

.chartContainer h2 {
  font-size: 24px;
  color: #1C2D5D;
  text-align: center;
  font-weight: 600;
}

.buttonAction {
  font-weight: 600;
  border-radius: 5px;
  background-color: #208776;
  color: white;
  font-size: small;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  display: inline-block;
}

.buttonAction:hover {
  background-color: #196758;
}
