/* Wrapper externo que inclui título e bloco */
.scoreDashboardWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Título e data alinhados horizontalmente */
.scoreDashboardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

/* Título do questionário */
.questionarioTitulo {
  font-size: 32px;
  font-weight: 600;
  color: #1C2D5D;
  margin: 0;
}

/* Texto da data */
.dataResposta {
  font-size: 16px;
  color: #666;
  font-weight: 500;
}

/* Caixa cinza que engloba gráfico e respostas */
.scoreDashboardContainer {
  background-color: #F4F4F4; /* cinza claro */
  padding: 2rem;
  border-radius: 10px;
}

/* Gráfico e respostas lado a lado */
.chartsRow {
  display: flex;
  gap: 2rem;
}

/* Gráfico e caixa de respostas */
.chartItem {
  flex: 1;
  height: 400px;
  display: flex;
  flex-direction: column;
}

/* Caixa de respostas com fundo cinza claro sem borda */
.respostaBox {
  flex: 1;
  height: 400px;
  overflow-y: auto;
  padding: 10px;
  background-color: #F4F4F4;
  border: none;
  border-radius: 0;
}

/* Lista de respostas */
.respostaLista {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

/* Item de resposta */
.respostaItem {
  background-color: #fff;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
}

/* Texto da pergunta */
.perguntaTexto {
  font-weight: 600;
  margin-bottom: 0.3rem;
  color: #333;
}

/* Texto da resposta */
.respostaTexto {
  margin: 0;
  font-size: 14px;
  color: #555;
}
