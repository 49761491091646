.mainDiv{
    height: 100vh;
    width: 100vw;
    background-color: #F4F4F4;
}

.navbar{
    background-color: #E1F2F0;
    height: 40px;
}

.divImage{
    display: flex;
    justify-content: center;
    margin-left: 20px;
}

.image{
    max-width:70px;
    max-height:70px;
    width: auto;
    height: auto;
}

.containerVerde{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #B4DED8;
    max-width: 100vw;
    height: max-content;
    margin-left:30px;
    margin-right:30px;
    margin-top: 30px;
    padding: 20px 30px ;
    box-sizing: border-box;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-family: sans-serif;
    border: 1px solid #B4DED8;
    
}

.containerBranco{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    max-width: 100vw;
    height: max-content;
    margin-left:30px;
    margin-right:30px;
    padding: 20px 30px ;
    box-sizing: border-box;
    font-family: sans-serif;
    border: 1px solid #B4DED8;
}

.containerCinza{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #F2F2F2;
    max-width: 100vw;
    height: max-content;
    margin-left:30px;
    margin-right:30px;
    padding: 20px 30px ;
    box-sizing: border-box;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-family: sans-serif;
    border-right: 1px solid #B4DED8;;
    border-left: 1px solid #B4DED8;;
    border-bottom: 1px solid #B4DED8;;
    font-weight: 600;
    color: #333333;
    font-size: small;
}

.title{
    color: #333333;
    font-weight: 700;
    align-self: flex-start;
}

.info{
    color: #333333;
    font-weight: 600;
    font-size: small;
    margin-top: 30px;
    align-self: flex-start;
}
.progress{
    color: #3C5896;
    font-weight: 600;
    align-self: flex-start;
    text-align: center;
}
.divProgress{
    width: 100%;
    text-align: center;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.progressBar{
    width: 100%;
    margin-bottom: 50px;
    background-color: #C4C4C4;
    align-self: center;
}

.progressNum{
    font-weight: 600;
    font-size: smaller;
    margin: 5px;
    text-align: center;
    background: #198754;
    border-radius: 4px;
    color: #FFFFFF;
    width: 100%;
    max-width: 100px;
}

.balaoPergunta{
    background: #E1F2F0;
    border-radius: 0px 10px 10px 10px;
    flex-direction: row;
    margin-bottom: 30px;
    align-self: flex-start;
}

.balaoResposta{
    background: #208776;
    border-radius: 10px 10px 0px 10px;
    display: flex;
    flex-direction: row;
    align-self: flex-end;
}

.pergunta{
    font-size: small;
    font-weight: 500;
    color: #333333;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
    padding: 20px;
}

.resposta{
    font-size: small;
    font-weight: 500;
    color: #FFFFFF;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
    padding: 20px;
}

.divImagePerfil{
    display: inline;
}

.imagePerfil{
    border-radius: 50%;
    max-width:30px;
    max-height:30px;
    width: auto;
    height: auto;
}

.buttonEnviar{
    background: #208776;
    border-radius: 5px;
    color: #FFFFFF ;
    margin-left: 30px;
    font-size: small;
}

.buttonEnviar:hover{
    background: #208776;
    border-radius: 5px;
    color: #FFFFFF ;
    margin-left: 30px;
    font-size: small;
}

.buttonEnviar:focus{
    background: #208776;
    border-radius: 5px;
    color: #FFFFFF ;
    margin-left: 30px;
    font-size: small;
}

.refazerResposta{
    font-weight: 600;
    font-size: small;
    color: #208776;
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    margin-top: 10px;
}