.mainDiv {
    height: 100vh;
    width: 100vw;
    background-color: #F4F4F4;
}

.navbar {
    background-color: #E1F2F0;
    height: 40px;
}

.divImage {
    display: flex;
    justify-content: center;
    margin-left: 20px;
}

.image {
    max-width: 70px;
    max-height: 70px;
    width: auto;
    height: auto;
}

.containerVerde {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #B4DED8;
    max-width: 100vw;
    height: max-content;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;
    padding: 20px 30px;
    box-sizing: border-box;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-family: sans-serif;
    border: 1px solid #B4DED8;
}

.containerBranco {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    max-width: 100vw;
    height: max-content;
    margin-left: 30px;
    margin-right: 30px;
    padding: 20px 30px;
    box-sizing: border-box;
    font-family: sans-serif;
    border: 1px solid #B4DED8;
}

.containerTexto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    max-width: 100vw;
    height: max-content;
    margin-left: 25%;
    margin-right: 25%;
    padding: 20px 30px;
    box-sizing: border-box;
    font-family: sans-serif;
}

.balaoPergunta {
    background: #E1F2F0;
    border-radius: 0px 10px 10px 10px;
    flex-direction: row;
    margin-bottom: 30px;
    align-self: flex-start;
    padding: 20px;
    text-align: center;
}

.pergunta {
    font-size: small;
    font-weight: 500;
    color: #333333;
}

.balaoResposta {
    background: #208776;
    border-radius: 10px 10px 0px 10px;
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    padding: 10px;
    margin-top: 10px;
}

.labelCheck {
    font-size: small;
    font-weight: 500;
    color: #FFFFFF;
    margin-left: 10px;
    cursor: pointer;
}

input[type="checkbox"] {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.containerCinza {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #F2F2F2;
    max-width: 100vw;
    height: max-content;
    margin-left: 30px;
    margin-right: 30px;
    padding: 20px 30px;
    box-sizing: border-box;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-family: sans-serif;
    border-right: 1px solid #B4DED8;
    border-left: 1px solid #B4DED8;
    border-bottom: 1px solid #B4DED8;
    font-weight: 600;
    color: #333333;
    font-size: small;
}

.buttonEnviar {
    background: #208776;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: small;
    padding: 10px 20px;
    margin: 0px 10px;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease;
}

.buttonEnviar:disabled {
    background: #C4C4C4;
    cursor: not-allowed;
}

.buttonEnviar:hover:not(:disabled) {
    background: #186d5f;
}

.buttonRecusar {
    background: #bd2929;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: small;
    padding: 10px 20px;
    margin: 0px 10px;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease;
}

.buttonRecusar:disabled {
    background: #C4C4C4;
    cursor: not-allowed;
}

.buttonRecusar:hover:not(:disabled) {
    background: #6d1818;
}
