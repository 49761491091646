.buttonResposta{
    background: #FFFFFF;
    border: 1px solid #208776;
    box-sizing: border-box;
    border-radius: 5px;
    color: #333333;
    margin-left: 10px;
    font-size: small;
}

.buttonResposta:hover{
    background: #124B3D;
    border: 1px solid #208776;
    box-sizing: border-box;
    border-radius: 5px;
    color: #FFFFFF ;
    margin-left: 10px;
    font-size: small;
}

.buttonResposta:focus{
    background: #124B3D;
    border: 1px solid #208776;
    box-sizing: border-box;
    border-radius: 5px;
    color: #FFFFFF ;
    margin-left: 10px;
    font-size: small;
}